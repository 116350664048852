$textShadow1:
  0px 1px 4px rgba(58, 53, 51, 0.08),
  0px 0px 1px rgba(58, 53, 51, 0.25);

$textShadow2:
  0px 2px 12px rgba(58, 53, 51, 0.5), 0px 0px 2px rgba(58, 53, 51, 0.8);

$textShadow3:
  0px 4px 20px rgba(58, 53, 51, 0.15), 0px 0px 3px rgba(58, 53, 51, 0.1);

$textShadow4:
  0px 8px 40px rgba(58, 53, 51, 0.2), 0px 0px 4px rgba(58, 53, 51, 0.1);

:export {
  textShadow1: $textShadow1;
  textShadow2: $textShadow2;
  textShadow3: $textShadow3;
  textShadow4: $textShadow4;
}
