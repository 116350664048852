$extraSmallBreakpoint: 320px;
  $extraSmallMargins: 20px;
  $extraSmallGutters: 16px;
  
$smallBreakpoint: 600px;
  $smallMargins: 40px;
  $smallGutters: 32px;
  
$mediumBreakpoint: 905px;
  $mediumMargins: 40px;
  $mediumGutters: 32px;
  
  
$largeBreakpoint: 1240px;
  $largeMargins: 64px;
  $largeGutters: 32px;
  
$extraLargeBreakpoint: 1441px;
  $extraLargeMargins: 64px;
  $extraLargeGutters: 36px;

$maxWidthBreakpoint: 1920px;


$mediumLarge: 992px;

:export {
  extraSmallBreakpoint: $extraSmallBreakpoint;
  smallBreakpoint: $smallBreakpoint;
  mediumBreakpoint: $mediumBreakpoint;
  largeBreakpoint: $largeBreakpoint;
  extraLargeBreakpoint: $extraLargeBreakpoint;
}